import React, {useContext, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {UserContext} from "../contexts/UserContext";
import {Box, Tab, Tabs} from "@mui/material";
import {UsersList} from "../components/users/UsersList";
import {UserForm} from "../components/users/UserForm";

export const LayoutUsers = () => {
  const {user} = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: any): void => {
    setSelectedTab(newValue);
  };

  return (
      <Box style={{backgroundColor: '#f5f5f5', marginTop: '-60px'}}>
        <Box display="flex" alignItems="center" padding={2}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{flexGrow: 2, padding: 2}}>
              {user?.permissions?.users?.read ? (
                  <Tab label="All users"/>
              ):null}
              {user?.permissions?.users?.create ? (
                  <Tab label="Create user"/>
              ):null}
          </Tabs>
        </Box>
        {/* Tab Content */}
        <Box sx={{padding: 2}} flexGrow={1}>
            {selectedTab === 0 && user?.permissions?.users?.read && <div><UsersList/></div>}
            {selectedTab === 1 && user?.permissions?.users?.create && <div><UserForm mode={"create"}/></div>}
        </Box>
        <main>
          <Outlet/>
        </main>
      </Box>
  );
};