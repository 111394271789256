import React, {useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import {Box, IconButton, Tab, Tabs} from "@mui/material";
import MediaAnalyticsDashboard from "../components/dashboard/MediaAnalyticsDashboard";

const LayoutDashboard = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: any): void => {
        setSelectedTab(newValue);
    };

    return (
        <Box style={{backgroundColor: '#f5f5f5', marginTop: '-60px'}}>
            <Box display="flex" alignItems="center" padding={2}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{flexGrow: 2, padding: 2}}>
                    <Tab label="Top 5 Concepts"/>
                    {/*<Tab label="National"/>*/}
                </Tabs>
            </Box>
            {/* Tab Content */}
            <Box sx={{padding: 2}} flexGrow={1}>
                {selectedTab === 0 && <div><MediaAnalyticsDashboard isNational={false}/></div>}
                {/*{selectedTab === 1 && <div><MediaAnalyticsDashboard isNational={true}/></div>}*/}
            </Box>
        </Box>
    );
};

export {LayoutDashboard};