import React, {useContext, useEffect, useMemo, useState} from 'react';
import {NewsCluster, User, WikiConcept, TimelineEvent} from "../types";
import Collapse from '@mui/material/Collapse'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Snackbar,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import {
    ArrowDropDown,
    ArrowDropUp,
    UnfoldMore,
    ChevronLeft,
    ChevronRight,
    ContentCopy,
    Facebook,
    Report as ReportIcon,
    Search,
    ThumbDownAlt as ThumbDownAltIcon,
    ThumbUpAlt as ThumbUpAltIcon,
    Visibility as VisibilityIcon,
    X,
} from '@mui/icons-material';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {endOfDay, format, startOfDay, startOfToday, startOfYesterday, subDays} from 'date-fns';
import ExcelJS from 'exceljs'; // Import ExcelJS library for Excel export
import saveAs from 'file-saver'; // Updated import statement
import {Bar} from 'react-chartjs-2';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import excelDownloadButton from '../Excel-Download-button.jpg';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {SentimentHistogram} from "../components/sentiment/SentimentHistogram";
import {api} from "../api/apiService";

// Register the chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

// For sorting
type SortOrder = 'asc' | 'desc' | null;
type SortField = 'article_count' | 'country' | 'published_at' | null;

const LayoutNewsClusters = () => {
    const [searchTerm, setSearchTerm] = useState<string>(''); // String type
    const [countryFilter, setCountryFilter] = useState<string[]>([]);
    const [countryAnchorEl, setCountryAnchorEl] = useState<null | HTMLElement>(null);
    const [countrySearchTerm, setCountrySearchTerm] = useState('');
    const [articleCountFilter, setArticleCountFilter] = useState<string[]>([]);
    const [articleCountAnchorEl, setArticleCountAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedClusters, setSelectedClusters] = useState<NewsCluster[]>([]); // Track selected clusters

    // New state variables for user management
    const [detailsTab, setDetailsTab] = useState(0); // New state for details window tab

    const [users, setUsers] = useState<User[]>([]); // initial list of users

    const [currentPage, setCurrentPage] = useState<number>(1); // Number type
    const [selectedClusterForDetails, setSelectedClusterForDetails] = useState<NewsCluster | null>(null);
    const [clusters, setClusters] = useState<NewsCluster[]>([]);

    // Cluster feedback states (thumbs up/down)
    const [clusterFeedbackError, setClusterFeedbackError] = useState('');
    const [clusterFeedbackSuccess, setClusterFeedbackSuccess] = useState('');
    const [showClusterFeedbackAlert, setShowClusterFeedbackAlert] = useState(false);

    // Incorrect articles states
    const [articleFeedbackError, setArticleFeedbackError] = useState('');
    const [articleFeedbackSuccess, setArticleFeedbackSuccess] = useState('');
    const [incorrectArticles, setIncorrectArticles] = useState<string[]>([]);
    const [showArticleFeedbackAlert, setShowArticleFeedbackAlert] = useState(false);

    // Sorting
    const [sortField, setSortField] = useState<SortField>(null);
    const [sortOrder, setSortOrder] = useState<SortOrder>(null);

    const itemsPerPage = 10;

    const contrastingViews = selectedClusterForDetails?.contrasting_views || [];

    const expressedViews = contrastingViews.filter(
        (view) => view.point_of_view_type === 'expressed'
    );

    const suggestedViews = contrastingViews.filter(
        (view) => view.point_of_view_type === 'suggested'
    );

    // Helper to get the right icon based on sorting state
    const getSortIcon = (field: SortField) => {
        if (sortField === field && sortOrder === 'asc') {
            return <ArrowDropUp fontSize="small" style={{ marginLeft: '4px' }} />;
        } else if (sortField === field && sortOrder === 'desc') {
            return <ArrowDropDown fontSize="small" style={{ marginLeft: '4px' }} />;
        } else {
            // Neutral icon if not sorted, or sorted on another field
            return <UnfoldMore fontSize="small" style={{ marginLeft: '4px' }} />;
        }
    };

    // Third-click logic: not sorted anymore
    const handleSort = (field: SortField) => {
        if (sortField !== field) {
            // We were sorting by something else (or not at all), so start fresh on this field asc
            setSortField(field);
            setSortOrder('asc');
        } else {
            // Already sorting by this field
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else if (sortOrder === 'desc') {
                // Third click => clear the sorting
                setSortField(null);
                setSortOrder(null);
            } else {
                // If, for some reason, we had null, go back to asc
                setSortOrder('asc');
            }
        }
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [clusters, searchTerm, countryFilter, articleCountFilter]);

    useEffect(() => {
        // Fetch news clusters
        api.get("/api/catholic_news_summary")
            .then((res) => {
                setClusters(res.data);
            })
            .catch((err) => {
                console.error("Error fetching news clusters:", err);
            });
    }, []);

    const allCountries = useMemo(() => {
        const countriesSet = new Set<string>();
        clusters.forEach((cluster) => {
            cluster.country
                .split(',')
                .map((country) => country.trim())
                .forEach((country) => {
                    countriesSet.add(country);
                });
        });
        return Array.from(countriesSet).sort((a, b) =>
            a.localeCompare(b, 'sl', { sensitivity: 'base' })
        );
    }, [clusters]);

    const filteredCountries = useMemo(() => {
        return allCountries
            .filter((country) => country.toLowerCase().includes(countrySearchTerm.toLowerCase()))
            .sort((a, b) => a.localeCompare(b, 'sl', { sensitivity: 'base' }));
    }, [allCountries, countrySearchTerm]);

    // Create data for the histogram in details dialog
    const sentimentScores =
        selectedClusterForDetails?.articles.map((article) => article.sentiment_score || 0) || [];

    // Bin the sentiment scores
    const binWidthDetails = 0.1;
    const numBinsDetails = Math.ceil(1 / binWidthDetails); // Should be 10 bins
    const binsDetails = new Array(numBinsDetails).fill(0);

    sentimentScores.forEach((score) => {
        let binIndex = Math.floor(score / binWidthDetails);
        if (binIndex >= numBinsDetails) {
            binIndex = numBinsDetails - 1; // Edge case when score is exactly 1
        }
        binsDetails[binIndex]++;
    });

    // Create labels for the bins in the format <0.1, <0.2, ..., <1
    const binLabelsDetails = [];
    for (let i = 0; i < numBinsDetails; i++) {
        const end = ((i + 1) * binWidthDetails).toFixed(1);
        binLabelsDetails.push(`<${end}`);
    }

    // Create the data for Chart.js
    const chartDataDetails = {
        labels: binLabelsDetails,
        datasets: [
            {
                label: 'Number of Articles',
                data: binsDetails,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const handleDetailsTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setDetailsTab(newValue);
    };

    // Chart.js options for customization
    const chartOptionsDetails = {
        scales: {
            x: {
                grid: {
                    display: false, // This hides the vertical gridlines
                },
                title: {
                    display: true,
                    text: 'Sentiment Score',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Articles',
                },
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    // Article count options
    const articleCountOptions = ['<5', '5-9', '10-14', '15+'];

    // Filtered clusters (search, country, article count, date)
    const filteredClusters = clusters.filter((cluster) => {
        const keywords = Array.isArray(cluster.keywords)
            ? cluster.keywords.join(', ')
            : cluster.keywords;

        const wikiConceptsString = cluster.wiki_concepts
            ? cluster.wiki_concepts.map((concept) => concept.concept).join(' ')
            : '';

        const matchesSearch =
            keywords.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cluster.general_overview.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cluster.detailed_summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
            wikiConceptsString.toLowerCase().includes(searchTerm.toLowerCase());

        const clusterCountries = cluster.country.split(',').map((country) => country.trim());
        const matchesCountry =
            countryFilter.length === 0 ||
            clusterCountries.some((country) => countryFilter.includes(country));

        const matchesArticleCount =
            articleCountFilter.length === 0 ||
            articleCountFilter.some((range) => {
                const articleCount = cluster['article_count'];
                switch (range) {
                    case '<5':
                        return articleCount < 5;
                    case '5-9':
                        return articleCount >= 5 && articleCount <= 9;
                    case '10-14':
                        return articleCount >= 10 && articleCount <= 14;
                    case '15+':
                        return articleCount >= 15;
                    default:
                        return false;
                }
            });

        return matchesSearch && matchesCountry && matchesArticleCount;
    });

    // Sort the filtered clusters based on sortField and sortOrder
    const sortedClusters = useMemo(() => {
        const sorted = [...filteredClusters];
        if (sortField) {
            sorted.sort((a, b) => {
                if (sortField === 'article_count') {
                    return a.article_count - b.article_count;
                } else if (sortField === 'country') {
                    return a.country.localeCompare(b.country);
                } else if (sortField === 'published_at') {
                    // Sort by the earliest article date in each cluster
                    const aDate = new Date(a.articles[0].published_at).getTime();
                    const bDate = new Date(b.articles[0].published_at).getTime();
                    return aDate - bDate;
                }
                return 0;
            });
            if (sortOrder === 'desc') {
                sorted.reverse();
            }
        }
        return sorted;
    }, [filteredClusters, sortField, sortOrder]);

    const displayedClusters = sortedClusters.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    // Handle selecting clusters
    const handleClusterSelect = (cluster: NewsCluster) => {
        const isSelected = selectedClusters.some((selected) => selected.title === cluster.title);
        if (isSelected) {
            setSelectedClusters(selectedClusters.filter((selected) => selected.title !== cluster.title));
        } else {
            setSelectedClusters([...selectedClusters, cluster]);
        }
    };

    // Handler for "Select All" checkbox
    const handleSelectAllClusters = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedClusters(sortedClusters);
        } else {
            setSelectedClusters([]);
        }
    };

    // Function to render the sentiment score bar with a callout
    const renderSentimentBar = (score: number | undefined) => {
        if (score === undefined) return null;

        const scorePercentage = score * 100;

        return (
            <Box sx={{position: 'relative', width: '150px', height: '20px'}}>
                {/* Sentiment Bar */}
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        background: 'linear-gradient(to right, #FFB3BA, #FFFFBA, #BAFFC9)',
                    }}
                />
                {/* Callout */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-25px',
                        left: `${scorePercentage}%`,
                        transform: 'translateX(-50%)',
                        padding: '2px 5px',
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '3px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        boxShadow: '0px 0px 3px rgba(0,0,0,0.2)',
                    }}
                >
                    {`${(score * 100).toFixed(1)}%`}
                </Box>
                {/* Marker on the bar */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: `${scorePercentage}%`,
                        transform: 'translateX(-50%)',
                        width: '2px',
                        height: '100%',
                        backgroundColor: '#B3CDE0',
                    }}
                />
            </Box>
        );
    };

    // Export to Excel functionality using ExcelJS
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Selected Clusters');

        // Define the headers
        worksheet.columns = [
            {header: 'Article Count', key: 'articleCount', width: 10},
            {header: 'Country', key: 'country', width: 15},
            {header: 'Keywords', key: 'keywords', width: 30},
            {header: 'General Overview', key: 'generalOverview', width: 40},
            {header: 'Detailed Summary', key: 'detailedSummary', width: 70},
            {header: 'Title', key: 'title', width: 50},
            {header: 'Source', key: 'source', width: 20},
            {header: 'URL', key: 'url', width: 30},
            {header: 'Sentiment Score', key: 'sentimentScore', width: 15},
            {header: 'Published At', key: 'publishedAt', width: 15},
        ];

        // Apply styles to the header row
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = {bold: true};
            cell.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};
        });

        let currentRow = 2; // Start from the second row

        // Filter only the selected clusters that are currently visible on the page
        const clustersToExport = displayedClusters.filter(cluster =>
            selectedClusters.some(selected => selected.title === cluster.title)
        );

        for (const cluster of clustersToExport) {
            const clusterKeywords = Array.isArray(cluster.keywords)
                ? cluster.keywords.join(', ')
                : cluster.keywords;

            const startRow = currentRow;

            for (const article of cluster.articles) {
                worksheet.addRow({
                    articleCount: cluster['article_count'],
                    country: cluster.country,
                    keywords: clusterKeywords,
                    generalOverview: cluster['general_overview'],
                    detailedSummary: cluster['detailed_summary'],
                    title: article.article_title,
                    source: article.source,
                    url: article.url,
                    sentimentScore: article.sentiment_score,
                    publishedAt: formatDate(article['published_at']),
                });

                // Apply styles to the cells in the row
                const row = worksheet.getRow(currentRow);
                ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'I', 'J'].forEach((col) => {
                    const cell = row.getCell(col);
                    cell.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};
                });
                ['H'].forEach((col) => {
                    const cell = row.getCell(col);
                    cell.alignment = {vertical: 'middle'};
                });

                currentRow++;
            }

            const endRow = currentRow - 1;

            // Merge cells for cluster-level data
            ['A', 'B', 'C', 'D', 'E'].forEach((col) => {
                worksheet.mergeCells(`${col}${startRow}:${col}${endRow}`);
                const cell = worksheet.getCell(`${col}${startRow}`);
                cell.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};
            });
        }

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Save the file
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        saveAs(blob, 'selected_clusters.xlsx');
    };

    const exportSocialMediaToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Social Media Posts');

        // Define the headers for social media posts
        worksheet.columns = [
            {header: 'Platform', key: 'platform', width: 10},
            {header: 'Post Type', key: 'type', width: 15},
            {header: 'Content', key: 'content', width: 50},
            {header: 'Hashtags', key: 'hashtags', width: 30},
        ];

        // Apply styles to the header row
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = {bold: true};
            cell.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};
        });

        // Populate rows with social media data
        selectedClusterForDetails?.social_media.forEach((post) => {
            worksheet.addRow({
                platform: post.platform,
                type: post.post_type,
                content: post.post_content,
                hashtags: post.hashtags.join(', '),
            });
        });

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        saveAs(blob, 'social_media_posts.xlsx');
    };

    // Country popover handlers
    const handleCountryButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setCountryAnchorEl(event.currentTarget);
    };

    const handleCountryPopoverClose = () => {
        setCountryAnchorEl(null);
        setCountrySearchTerm(''); // Reset the search term when closing
    };

    const isCountryPopoverOpen = Boolean(countryAnchorEl);

    const handleCountryToggle = (country: string) => {
        const currentIndex = countryFilter.indexOf(country);
        const newChecked = [...countryFilter];

        if (currentIndex === -1) {
            newChecked.push(country);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCountryFilter(newChecked);
    };

    // Article Count popover handlers
    const handleArticleCountButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setArticleCountAnchorEl(event.currentTarget);
    };

    const handleArticleCountPopoverClose = () => {
        setArticleCountAnchorEl(null);
    };

    const isArticleCountPopoverOpen = Boolean(articleCountAnchorEl);

    const handleArticleCountToggle = (range: string) => {
        const currentIndex = articleCountFilter.indexOf(range);
        const newChecked = [...articleCountFilter];

        if (currentIndex === -1) {
            newChecked.push(range);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setArticleCountFilter(newChecked);
    };

    type WikiType = 'persons' | 'organizations' | 'locations';

    const wikiTypeLabels: { [key in WikiType]: string } = {
        persons: 'People',
        organizations: 'Organizations',
        locations: 'Locations',
    };

    const wikiTypes: WikiType[] = ['persons', 'organizations', 'locations'];

    // Extract wiki concepts from the selected cluster
    const wikiConcepts = selectedClusterForDetails?.wiki_concepts || [];

    type GroupedConcepts = {
        [key in WikiType]?: WikiConcept[];
    };

    // Group concepts by their type
    const groupedConcepts = wikiConcepts.reduce(
        (acc: GroupedConcepts, concept: WikiConcept) => {
            const type = concept.wiki_type;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type]!.push(concept);
            return acc;
        },
        {} as GroupedConcepts
    );

    // Sort the concepts within each wiki type
    wikiTypes.forEach((type) => {
        if (groupedConcepts[type]) {
            groupedConcepts[type]!.sort((a, b) => a.concept.localeCompare(b.concept));
        }
    });

    const [likedClusters, setLikedClusters] = useState<string[]>([]);
    const [dislikedClusters, setDislikedClusters] = useState<string[]>([]);

    // Handle cluster feedback (Thumbs up/down)
    const handleClusterFeedback = (cluster: NewsCluster, positive: boolean) => {
        setClusterFeedbackError('');
        setClusterFeedbackSuccess('');
        try {
            api.post('/api/feedback/clusters/mismatch', {
                cluster_id: cluster.title,
                positive: positive
            }).then(res => {
                setClusterFeedbackSuccess('Feedback submitted successfully!');
            })
        } catch (error: any) {
            setClusterFeedbackError(error.message || 'An unexpected error occurred.');
        } finally {
            setShowClusterFeedbackAlert(true);
        }
    };

    // Incorrect articles states
    const handleArticleFeedback = async (clusterId: string, articleUrl: string) => {
        setArticleFeedbackError('');
        setArticleFeedbackSuccess('');

        // Toggle first so the user sees the color change right away:
        const isIncorrect = incorrectArticles.includes(articleUrl);
        setIncorrectArticles((prev) =>
            isIncorrect
                ? prev.filter((url) => url !== articleUrl)
                : [...prev, articleUrl]
        );

        // Then attempt your request
        try {
            api.post('/api/feedback/articles/mismatch', {
                cluster_id: clusterId,
                article_url: articleUrl,
                mismatch: !isIncorrect
            }).then(res => {
                setArticleFeedbackSuccess('Feedback submitted successfully!');
                setShowArticleFeedbackAlert(true);
            })
        } catch (error: any) {
            setArticleFeedbackError(error.message || 'An unexpected error occurred.');
            setShowArticleFeedbackAlert(true);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection="column" minHeight="100vh" bgcolor="#f5f5f5">
                {/* Header */}
                <Box borderBottom={1} borderColor="divider">
                    <Box display="flex" alignItems="center" padding={2}>

                        {/* Middle: Search Bar & Excel */}
                        <Box display="flex" alignItems="center" justifyContent="center" flex="2" sx={{marginTop: "-40px"}}>
                            <TextField
                                variant="outlined"
                                placeholder="Search clusters..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ width: '639px' }}
                            />
                            <Button
                                variant="contained"
                                onClick={exportToExcel}
                                disabled={selectedClusters.length === 0}
                                style={{
                                    marginLeft: '16px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #D3D3D3',
                                    borderRadius: '8px',
                                    padding: '5px',
                                }}
                            >
                                <img
                                    src={excelDownloadButton}
                                    alt="Excel Download"
                                    style={{
                                        width: '50px',
                                        height: '45px',
                                        backgroundColor: '#FFFFFF',
                                    }}
                                />
                            </Button>
                        </Box>
                    </Box>

                    {/* Buttons below the search bar */}
                    <Box display="flex" justifyContent="center" mt={-3} paddingBottom={2} gap={2}>
                        <Button
                            variant="outlined"
                            onClick={handleArticleCountButtonClick}
                            endIcon={<ArrowDropDown/>}
                            style={{
                                backgroundColor: '#4a4a4a', // Dark grey background
                                borderRadius: '50px', // Rounded corners
                                border: '1px solid #ccc', // Thin border
                                padding: '6px 16px', // Add some padding for shape
                                fontWeight: 'bold', // Bold text
                                color: 'white', // White text
                                textTransform: 'none', // Keep the text as it is, no uppercase
                                width: '180px', // Keep the width style
                                marginTop: '15px'
                            }}
                        >
                            Article Count {articleCountFilter.length > 0 ? `(${articleCountFilter.length})` : ''}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleCountryButtonClick}
                            endIcon={<ArrowDropDown/>}
                            style={{
                                backgroundColor: '#4a4a4a', // Dark grey background
                                borderRadius: '50px', // Rounded corners
                                border: '1px solid #ccc', // Thin border
                                padding: '6px 16px', // Add some padding for shape
                                fontWeight: 'bold', // Bold text
                                color: 'white', // White text
                                textTransform: 'none', // Keep the text as it is, no uppercase
                                width: '180px', // Keep the width style
                                marginTop: '15px'
                            }}
                        >
                            Country {countryFilter.length > 0 ? `(${countryFilter.length})` : ''}
                        </Button>
                    </Box>
                </Box>

                {/* Main Content */}
                <Box flexGrow={1} padding={1}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* Updated Table Header Cell with "Select All" Checkbox */}
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '3%'}}>
                                        <Checkbox
                                            indeterminate={
                                                selectedClusters.length > 0 && selectedClusters.length < sortedClusters.length
                                            }
                                            checked={
                                                sortedClusters.length > 0 && selectedClusters.length === sortedClusters.length
                                            }
                                            onChange={handleSelectAllClusters}
                                        />
                                    </TableCell>
                                    {/* Sortable Article Count */}
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '5%', cursor: 'pointer'}}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            whiteSpace="nowrap"
                                            onClick={() => handleSort('article_count')}
                                        >
                                            <Box>Article Count</Box>
                                            {getSortIcon('article_count')}
                                        </Box>
                                    </TableCell>
                                    {/* Sortable Country */}
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '12%', cursor: 'pointer'}}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            whiteSpace="nowrap"
                                            onClick={() => handleSort('country')}
                                        >
                                            <Box>Country</Box>
                                            {getSortIcon('country')}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '8%'}}>
                                        Keywords
                                    </TableCell>
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '35%'}}>
                                        General Overview
                                    </TableCell>
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '25%'}}>
                                        Sentiment Score
                                    </TableCell>
                                    {/* Sortable Date */}
                                    <TableCell align="center" style={{fontWeight: 'bold', width: '19%', cursor: 'pointer'}}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            whiteSpace="nowrap"
                                            onClick={() => handleSort('published_at')}
                                        >
                                            <Box>Date</Box>
                                            {getSortIcon('published_at')}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" style={{width: '5%'}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedClusters.map((cluster, index) => (
                                    <TableRow
                                        key={index}
                                        style={{
                                            backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                                        }}
                                    >
                                        <TableCell align="center" style={{width: '5%'}}>
                                            <Checkbox
                                                checked={selectedClusters.some((selected) => selected.title === cluster.title)}
                                                onChange={() => handleClusterSelect(cluster)}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{width: '10%'}}>
                                            {cluster['article_count']}
                                        </TableCell>
                                        <TableCell align="center" style={{width: '10%'}}>
                                            {cluster.country}
                                        </TableCell>
                                        <TableCell style={{width: '20%'}}>
                                            <Typography variant="body2">
                                                {(Array.isArray(cluster.keywords)
                                                        ? cluster.keywords
                                                        : cluster.keywords.split(',')
                                                ).map((keyword, idx, arr) => (
                                                    <span key={idx} style={{fontWeight: 'bold'}}>
                            {keyword.trim()}
                                                        {idx < arr.length - 1 && <span style={{margin: '0 4px'}}> • </span>}
                          </span>
                                                ))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{width: '25%'}}>{cluster['general_overview']}</TableCell>
                                        {/* Sentiment Score Cell with Histogram */}
                                        <TableCell align="center" style={{width: '20%'}}>
                                            <SentimentHistogram articles={cluster.articles}/>
                                        </TableCell>
                                        <TableCell align="center" style={{width: '10%'}}>
                                            {formatDate(cluster.articles[0]['published_at'])}
                                        </TableCell>
                                        <TableCell align="center" style={{width: '5%'}}>
                                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedClusterForDetails(cluster);
                                                        setDetailsTab(0); // <-- Reset to the first tab
                                                    }}
                                                >
                                                    <VisibilityIcon/>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleClusterFeedback(cluster, true);
                                                        setLikedClusters(prev =>
                                                            prev.includes(cluster.title)
                                                                ? prev.filter(title => title !== cluster.title)
                                                                : [...prev, cluster.title]
                                                        );
                                                        setDislikedClusters(prev => prev.filter(title => title !== cluster.title));
                                                    }}
                                                >
                                                    <ThumbUpAltIcon color={likedClusters.includes(cluster.title) ? 'primary' : 'inherit'}/>
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => {
                                                        handleClusterFeedback(cluster, false);
                                                        setDislikedClusters(prev =>
                                                            prev.includes(cluster.title)
                                                                ? prev.filter(title => title !== cluster.title)
                                                                : [...prev, cluster.title]
                                                        );
                                                        setLikedClusters(prev => prev.filter(title => title !== cluster.title));
                                                    }}
                                                >
                                                    <ThumbDownAltIcon color={dislikedClusters.includes(cluster.title) ? 'primary' : 'inherit'}/>
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
                        <Typography>
                            Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
                            {Math.min(currentPage * itemsPerPage, sortedClusters.length)} of{' '}
                            {sortedClusters.length} results
                        </Typography>
                        <Box>
                            <IconButton
                                onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
                                disabled={currentPage === 1}
                            >
                                <ChevronLeft/>
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.min(Math.ceil(sortedClusters.length / itemsPerPage), prev + 1)
                                    )
                                }
                                disabled={clusters.length === 0 || currentPage === Math.ceil(sortedClusters.length / itemsPerPage)}
                            >
                                <ChevronRight/>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

                {/* Country Filter Popover */}
                <Popover
                    open={isCountryPopoverOpen}
                    anchorEl={countryAnchorEl}
                    onClose={handleCountryPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box p={2} style={{width: '300px'}}>
                        {/* Title and Clear button on the same line */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" style={{fontWeight: 'bold'}}>
                                Countries
                            </Typography>
                            <Button onClick={() => setCountryFilter([])}>Clear</Button>
                        </Box>
                        {/* Search bar */}
                        <TextField
                            variant="outlined"
                            placeholder="Search countries..."
                            value={countrySearchTerm}
                            onChange={(e) => setCountrySearchTerm(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        {/* Country list */}
                        <List style={{maxHeight: '200px', overflow: 'auto'}}>
                            {filteredCountries.map((country) => (
                                <ListItem key={country} dense button onClick={() => handleCountryToggle(country)}>
                                    <Checkbox
                                        edge="start"
                                        checked={countryFilter.indexOf(country) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={country}/>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Popover>

                {/* Article Count Filter Popover */}
                <Popover
                    open={isArticleCountPopoverOpen}
                    anchorEl={articleCountAnchorEl}
                    onClose={handleArticleCountPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box p={2} style={{width: '300px'}}>
                        {/* Title and Clear button on the same line */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <Typography variant="h6" style={{fontWeight: 'bold'}}>
                                Article Count
                            </Typography>
                            <Button onClick={() => setArticleCountFilter([])}>Clear</Button>
                        </Box>
                        {/* Article Count options */}
                        <List>
                            {articleCountOptions.map((range) => (
                                <ListItem key={range} dense button onClick={() => handleArticleCountToggle(range)}>
                                    <Checkbox
                                        edge="start"
                                        checked={articleCountFilter.indexOf(range) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={range}/>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Popover>

                {/* Details Dialog with Tabs */}
                <Dialog
                    open={!!selectedClusterForDetails}
                    onClose={() => setSelectedClusterForDetails(null)}
                    maxWidth="lg" // Make the dialog wider
                    fullWidth
                    PaperProps={{
                        style: {
                            maxHeight: '100vh',
                        },
                    }}
                >
                    {selectedClusterForDetails && (
                        <>
                            {/* Bold title in the details window */}
                            <DialogTitle>
                                <Typography variant="h5" style={{fontWeight: 'bold', color: '#4A4A4A'}}>
                                    {selectedClusterForDetails.title}
                                </Typography>
                            </DialogTitle>

                            <Tabs
                                value={detailsTab}
                                onChange={handleDetailsTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab label="Articles"/>
                                <Tab label="Overview"/>
                                <Tab label="AI Draft Article"/>
                                <Tab label="Social Media"/>
                                <Tab label="Contrasting Views"/>
                                <Tab label="Wiki Concepts"/>
                                {selectedClusterForDetails?.timeline.length > 0 && <Tab label="Timeline" />}
                            </Tabs>

                            <DialogContent dividers style={{
                                padding: 10,
                                overflowY: 'auto',
                                height: '600px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {detailsTab === 0 && (
                                    <Box padding={2} flexShrink={0} style={{marginTop: '2px'}}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                flexShrink: 0,
                                                fontWeight: 'bold',
                                                color: '#4A4A4A',
                                                marginBottom: '16px'
                                            }}
                                        >
                                            Articles
                                        </Typography>
                                        <TableContainer component={Paper} style={{flexGrow: 1, overflow: 'auto'}}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '40%',
                                                                backgroundColor: '#4A4A4A',
                                                                color: 'white',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            Title
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '20%',
                                                                backgroundColor: '#4A4A4A',
                                                                color: 'white',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                            }}
                                                        >
                                                            Source
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '25%',
                                                                backgroundColor: '#4A4A4A',
                                                                color: 'white',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                            }}
                                                        >
                                                            Sentiment Score
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '15%',
                                                                backgroundColor: '#4A4A4A',
                                                                color: 'white',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                            }}
                                                        >
                                                            Published At
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '15%',
                                                                backgroundColor: '#4A4A4A',
                                                                color: 'white',
                                                                fontSize: '1rem',
                                                                padding: '4px 8px',
                                                            }}
                                                        >
                                                            Incorrect?
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedClusterForDetails.articles.map((article, idx) => (
                                                        <TableRow
                                                            key={idx}
                                                            style={{
                                                                backgroundColor: idx % 2 === 0 ? '#f5f5f5' : 'white',
                                                                height: '80px',
                                                            }}
                                                        >
                                                            <TableCell>{article.article_title}</TableCell>
                                                            <TableCell align="center">
                                                                <a href={article.url} target="_blank" rel="noopener noreferrer">
                                                                    {article.source}
                                                                </a>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box display="flex" justifyContent="center">
                                                                    {renderSentimentBar(article.sentiment_score)}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {formatDate(article['published_at'])}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <IconButton
                                                                    onClick={() => handleArticleFeedback(selectedClusterForDetails.title, article.url)}
                                                                >
                                                                    <ReportIcon
                                                                        color={incorrectArticles.includes(article.url) ? 'primary' : 'inherit'}
                                                                    />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}

                                {detailsTab === 1 && (
                                    <Box display="flex" flexDirection="column">
                                        {/* Detailed Summary Section */}
                                        <Box padding={2} flexShrink={0} style={{marginTop: '2px'}}>
                                            <Typography variant="h6" style={{fontWeight: 'bold', color: '#4A4A4A'}}>
                                                Detailed Summary
                                            </Typography>
                                            <Box mt={1}>
                                                <Typography variant="body1" gutterBottom>
                                                    {selectedClusterForDetails['detailed_summary']}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {/* Sentiment Score Histogram */}
                                        <Box mb={0} mt={-3} padding={2} flexShrink={0}>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                style={{fontWeight: 'bold', color: '#4A4A4A'}}
                                            >
                                                Sentiment Score Distribution
                                            </Typography>
                                            <Box mt={0} mb={0} display="flex" justifyContent="center">
                                                <Box width="100%" maxWidth="500px" height="240px">
                                                    <Bar data={chartDataDetails} options={chartOptionsDetails}/>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                {detailsTab === 2 && (
                                <Box mt={4} padding={2} style={{ maxWidth: '700px', margin: '0 auto' }}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    Suggested Titles
                                    </Typography>
                                    {selectedClusterForDetails.suggested_titles?.map((title, idx) => (
                                    <Typography key={idx} variant="body1" style={{ marginBottom: '8px' }}>
                                        • {title}
                                    </Typography>
                                    ))}

                                    <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '24px', marginBottom: '8px' }}>
                                    Article
                                    </Typography>

                                    {selectedClusterForDetails.article_text
                                    ?.split('\n')
                                    .map((paragraph, idx) => (
                                        <Typography key={idx} variant="body1" paragraph>
                                        {paragraph}
                                        </Typography>
                                    ))
                                    }
                                </Box>
                                )}
                                {detailsTab === 3 && selectedClusterForDetails && (
                                    <Box padding={2} flexShrink={0}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="h6" style={{fontWeight: 'bold', color: '#4A4A4A'}}>
                                                Social Media Promotions
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                onClick={exportSocialMediaToExcel}
                                                style={{
                                                    marginLeft: '16px',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: '#FFFFFF',
                                                    border: '1px solid #D3D3D3',
                                                    borderRadius: '8px',
                                                    padding: '5px',
                                                }}
                                            >
                                                <img
                                                    src={excelDownloadButton}
                                                    alt="Excel Download"
                                                    style={{
                                                        width: '50px',
                                                        height: '45px',
                                                        backgroundColor: '#FFFFFF',
                                                    }}
                                                />
                                            </Button>
                                        </Box>

                                        <Box display="flex" justifyContent="space-between" gap={2}>
                                            {/* Define the order of post types */}
                                            {/** Ordered as: Educational, then Debate Hook, then CTA Teaser */}
                                            {(() => {
                                                const orderedPostTypes = ["Educational", "Debate Hook", "CTA Teaser"];
                                                return (
                                                    <>
                                                        {/* Facebook Column */}
                                                        <Box flex={1}>
                                                            <Box display="flex" alignItems="center" gap={1} style={{ paddingLeft: '16px' }}>
                                                                <Facebook style={{ fontSize: 24 }} />
                                                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                                                    Facebook
                                                                </Typography>
                                                            </Box>
                                                            {orderedPostTypes.map((type) => {
                                                                const postsOfType = selectedClusterForDetails.social_media.filter(
                                                                    (post) => post.platform === "Facebook" && post.post_type === type
                                                                );
                                                                if (postsOfType.length === 0) return null;
                                                                return (
                                                                    <Box key={type} mb={2}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{ fontWeight: 'bold', marginBottom: '8px', marginLeft: '16px' }}
                                                                        >
                                                                            {type}
                                                                        </Typography>
                                                                        {postsOfType.map((post, index) => (
                                                                            <Box
                                                                                key={index}
                                                                                mb={3}
                                                                                p={2}
                                                                                border={1}
                                                                                borderColor="divider"
                                                                                borderRadius="8px"
                                                                                position="relative"
                                                                                sx={{
                                                                                    paddingRight: '40px',
                                                                                    wordBreak: 'break-word'
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" color="textSecondary" paragraph>
                                                                                    {post.post_content}
                                                                                </Typography>
                                                                                <Box display="flex" flexWrap="wrap" gap={1}>
                                                                                    {post.hashtags &&
                                                                                        post.hashtags.map((hashtag, i) => (
                                                                                            <Chip
                                                                                                key={i}
                                                                                                label={hashtag}
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                            />
                                                                                        ))}
                                                                                </Box>
                                                                                {/* Copy to Clipboard Button */}
                                                                                <Box position="absolute" top={8} right={8}>
                                                                                    <CopyToClipboard text={post.post_content}>
                                                                                        <IconButton size="small">
                                                                                            <ContentCopy fontSize="small" />
                                                                                        </IconButton>
                                                                                    </CopyToClipboard>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>

                                                        {/* X Column */}
                                                        <Box flex={1}>
                                                            <Box display="flex" alignItems="center" gap={1} style={{ paddingLeft: '16px' }}>
                                                                <X style={{ fontSize: 24 }} />
                                                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                                                    X
                                                                </Typography>
                                                            </Box>
                                                            {orderedPostTypes.map((type) => {
                                                                const postsOfType = selectedClusterForDetails.social_media.filter(
                                                                    (post) => post.platform === "X" && post.post_type === type
                                                                );
                                                                if (postsOfType.length === 0) return null;
                                                                return (
                                                                    <Box key={type} mb={2}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{ fontWeight: 'bold', marginBottom: '8px', marginLeft: '16px' }}
                                                                        >
                                                                            {type}
                                                                        </Typography>
                                                                        {postsOfType.map((post, index) => (
                                                                            <Box
                                                                                key={index}
                                                                                mb={3}
                                                                                p={2}
                                                                                border={1}
                                                                                borderColor="divider"
                                                                                borderRadius="8px"
                                                                                position="relative"
                                                                                sx={{
                                                                                    paddingRight: '40px',
                                                                                    wordBreak: 'break-word'
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" color="textSecondary" paragraph>
                                                                                    {post.post_content}
                                                                                </Typography>
                                                                                <Box display="flex" flexWrap="wrap" gap={1}>
                                                                                    {post.hashtags &&
                                                                                        post.hashtags.map((hashtag, i) => (
                                                                                            <Chip
                                                                                                key={i}
                                                                                                label={hashtag}
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                            />
                                                                                        ))}
                                                                                </Box>
                                                                                {/* Copy to Clipboard Button */}
                                                                                <Box position="absolute" top={8} right={8}>
                                                                                    <CopyToClipboard text={post.post_content}>
                                                                                        <IconButton size="small">
                                                                                            <ContentCopy fontSize="small" />
                                                                                        </IconButton>
                                                                                    </CopyToClipboard>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    </>
                                                );
                                            })()}
                                        </Box>
                                    </Box>
                                )}

                                {detailsTab === 4 && selectedClusterForDetails && (
                                    <Box padding={2} flexShrink={0}>
                                        <Box display="flex" justifyContent="space-between" gap={2}>
                                            {/* Expressed Views Column */}
                                            <Box flex={1}>
                                                <Typography variant="h6" style={{fontWeight: 'bold'}}>
                                                    Expressed Views
                                                </Typography>
                                                {expressedViews.map((view, index) => (
                                                    <Box
                                                        key={index}
                                                        mb={3}
                                                        p={2}
                                                        border={1}
                                                        borderColor="divider"
                                                        borderRadius="8px"
                                                        minHeight="100px"
                                                    >
                                                        <Typography variant="body2">{view.point_of_view_description}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                            {/* Suggested Views Column */}
                                            <Box flex={1}>
                                                <Typography variant="h6" style={{fontWeight: 'bold'}}>
                                                    Suggested Views
                                                </Typography>
                                                {suggestedViews.map((view, index) => (
                                                    <Box
                                                        key={index}
                                                        mb={3}
                                                        p={2}
                                                        border={1}
                                                        borderColor="divider"
                                                        borderRadius="8px"
                                                        minHeight="100px"
                                                    >
                                                        <Typography variant="body2">{view.point_of_view_description}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                                {detailsTab === 5 && (
                                    <Box padding={2}>
                                        {wikiTypes
                                            .filter((type) => (groupedConcepts[type]?.length ?? 0) > 0)
                                            .map((type) => (
                                                <Box key={type} mb={3}>
                                                    <Typography variant="h6" style={{fontWeight: 'bold'}}>
                                                        {wikiTypeLabels[type]}
                                                    </Typography>
                                                    <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                                                        {groupedConcepts[type]?.map((concept: WikiConcept, index: number) => (
                                                            <Button
                                                                key={index}
                                                                variant="outlined"
                                                                color="primary"
                                                                href={concept.wiki_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{textTransform: 'none'}}
                                                            >
                                                                {concept.concept}
                                                            </Button>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            ))}
                                        {/* Display a message if no concepts are available */}
                                        {Object.keys(groupedConcepts).length === 0 && (
                                            <Typography variant="body1" style={{fontStyle: 'italic'}}>
                                                No wiki concepts available for this cluster.
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                                {detailsTab === 6 && (
                                    <Box padding={2}>
                                        <Typography
                                            variant="h6"
                                            style={{ fontWeight: 'bold', color: '#4A4A4A', marginBottom: '16px' }}
                                        >
                                            Timeline
                                        </Typography>

                                        {selectedClusterForDetails.timeline.length === 0 ? (
                                            <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                                                No timeline events available.
                                            </Typography>
                                        ) : (
                                            <Box
                                                sx={{
                                                    borderLeft: '2px solid #ccc',
                                                    paddingLeft: '16px',
                                                }}
                                            >
                                                {selectedClusterForDetails.timeline.map((event: TimelineEvent, index: number) => (
                                                    <Box
                                                        key={index}
                                                        mb={2}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {/* Blue dot */}
                                                        <Box
                                                            sx={{
                                                                width: '8px',
                                                                height: '8px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#1976d2',
                                                                marginRight: '8px', // Space between dot and date
                                                            }}
                                                        />

                                                        {/* Date (if not "N/A") */}
                                                        {event.event_date !== 'N/A' && (
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    marginRight: '8px',
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                            >
                                                                {event.event_date}
                                                            </Typography>
                                                        )}

                                                        {/* Event description */}
                                                        <Typography variant="body1">{event.event_description}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setSelectedClusterForDetails(null)}>Close</Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>

                {/* Snackbar for cluster feedback success/error */}
                <Snackbar
                    open={showClusterFeedbackAlert}
                    autoHideDuration={6000}
                    onClose={() => setShowClusterFeedbackAlert(false)}
                    TransitionComponent={Collapse}
                >
                    {clusterFeedbackError ? (
                        <Alert onClose={() => setShowClusterFeedbackAlert(false)} severity="error" sx={{width: '100%'}}>
                            {clusterFeedbackError}
                        </Alert>
                    ) : clusterFeedbackSuccess ? (
                        <Alert onClose={() => setShowClusterFeedbackAlert(false)} severity="success" sx={{width: '100%'}}>
                            {clusterFeedbackSuccess}
                        </Alert>
                    ) : <></>}
                </Snackbar>

                {/* Snackbar for article feedback success/error */}
                <Snackbar
                    open={showArticleFeedbackAlert}
                    autoHideDuration={6000}
                    onClose={() => setShowArticleFeedbackAlert(false)}
                >
                    {articleFeedbackError ? (
                        <Alert onClose={() => setShowArticleFeedbackAlert(false)} severity="error" sx={{ width: '100%' }}>
                            {articleFeedbackError}
                        </Alert>
                    ) : articleFeedbackSuccess ? (
                        <Alert onClose={() => setShowArticleFeedbackAlert(false)} severity="success" sx={{ width: '100%' }}>
                            {articleFeedbackSuccess}
                        </Alert>
                    ) : <></>}
                </Snackbar>
            </Box>
        </LocalizationProvider>
    );
};

export default LayoutNewsClusters;
