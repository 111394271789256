import React, {useContext, useState} from "react";
import {
    Tab,
    Tabs,
    Box,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogTitle, DialogContent, Alert, CircularProgress, Snackbar
} from "@mui/material";
import logo from "../logo.png";
import {FeedbackRounded as FeedbackRoundedIcon} from "@mui/icons-material";
import {AccountIconButton} from "../components/account/AccountIconButton";
import LayoutNewsClusters from "./LayoutNewsClusters";
import {LayoutUsers} from "./LayoutUsers";
import {LayoutAnalyticProfiles} from "./LayoutAnalyticProfiles";
import {LayoutDashboard} from "./LayoutDashboard";
import {AccountPopover} from "../components/account/AccountPopover";
import {api} from "../api/apiService";
import Collapse from "@mui/material/Collapse";
import {UserContext} from "../contexts/UserContext";
import {Route} from "react-router-dom";
import {UsersList} from "../components/users/UsersList";
import {UnauthorizedPage} from "../components/UnauthorizedPage";

const LayoutMain = () => {
    const {user} = useContext(UserContext);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: any): void => {
        setSelectedTab(newValue);
    };


    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(null);

    // General feedback form states
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackDescription, setFeedbackDescription] = useState('');
    const [feedbackError, setFeedbackError] = useState('');
    const [feedbackSuccess, setFeedbackSuccess] = useState('');
    const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);

    // General feedback states
    const handleFeedbackSubmit = async () => {
        setFeedbackError('');
        setFeedbackSuccess('');
        if (!feedbackTitle.trim() || !feedbackDescription.trim()) {
            setFeedbackError('Please provide both title and description.');
            return;
        }
        setFeedbackSubmitting(true);
        try {
            api.post('/api/feedback/general', {
                title: feedbackTitle.trim(),
                description: feedbackDescription.trim(),
            }).then((res) => {
                setFeedbackSuccess('Feedback submitted successfully!');
                setFeedbackTitle('');
                setFeedbackDescription('');
            })
        } catch (error: any) {
            setFeedbackError(error.message || 'An unexpected error occurred.');
        } finally {
            setFeedbackSubmitting(false);
            setIsFeedbackDialogOpen(false)
        }
    };

    return (
        <Box style={{backgroundColor: '#f5f5f5'}}>
            <Box display="flex" alignItems="center" padding={2}>
                {/* Left: Logo */}
                <Box display="flex" alignItems="center" flex="1" flexDirection="column" minHeight="100%">
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            height: 70,
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                            borderRadius: '8px'
                        }}
                    />
                </Box>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{flexGrow: 2, padding: 2}}>
                    <Tab label="News"/>
                    <Tab label="Dashboard"/>
                    {user?.permissions?.users?.read ? (
                        <Tab label="Users Management"/>
                    ):null}

                    {user?.permissions?.analytic_profiles?.read ? (
                        <Tab label="Analytic Profiles Management"/>
                    ):null}
                </Tabs>

                {/* Right: Feedback & Account Icons */}
                <Box display="flex" alignItems="center" justifyContent="flex-end" flex="1" gap={2}>
                    <IconButton onClick={() => setIsFeedbackDialogOpen(true)}>
                        <FeedbackRoundedIcon style={{fontSize: 40}}/>
                    </IconButton>
                    <AccountIconButton onClick={(event) => setAccountAnchorEl(event.currentTarget)}/>
                </Box>

                {/* Feedback Form Dialog (General Feedback) */}
                <Dialog open={isFeedbackDialogOpen} onClose={() => setIsFeedbackDialogOpen(false)}>
                    <DialogTitle>Submit Feedback</DialogTitle>
                    <DialogContent>
                        {feedbackError && <Alert severity="error" style={{marginBottom: '8px'}}>{feedbackError}</Alert>}
                        <TextField
                            label="Title"
                            fullWidth
                            value={feedbackTitle}
                            onChange={(e) => setFeedbackTitle(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            value={feedbackDescription}
                            onChange={(e) => setFeedbackDescription(e.target.value)}
                            margin="normal"
                        />
                        <Box display="flex" justifyContent="flex-end" mt={1}>
                            <Button onClick={() => setIsFeedbackDialogOpen(false)} style={{marginRight: '8px'}}>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleFeedbackSubmit}
                                variant="contained"
                                color="primary"
                                disabled={feedbackSubmitting}
                            >
                                {feedbackSubmitting ? <CircularProgress size={24}/> : 'Submit'}
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>

                {/* Account Popover */}
                <AccountPopover
                    accountAnchorEl={accountAnchorEl}
                    setAccountAnchorEl={setAccountAnchorEl}
                />

                {/* Snackbar for general feedback success */}
                <Snackbar
                    open={!!feedbackSuccess}
                    autoHideDuration={6000}
                    onClose={() => setFeedbackSuccess("")}
                    TransitionComponent={Collapse}
                >
                    {!!feedbackSuccess ? (
                        <Alert onClose={() => setFeedbackSuccess("")} severity="success" sx={{width: '100%'}}>
                            {feedbackSuccess}
                        </Alert>
                    ) : <></>}
                </Snackbar>

            </Box>
            {/* Tab Content */}
            <Box sx={{padding: 2}} flexGrow={1} minHeight="100vh">
                {selectedTab === 0 && <div><LayoutNewsClusters/></div>}
                {selectedTab === 1 && <div><LayoutDashboard/></div>}
                {selectedTab === 2 && <div><LayoutUsers/></div>}
                {selectedTab === 3 && <div><LayoutAnalyticProfiles/></div>}
            </Box>
            <Box component="footer" sx={{textAlign: 'center'}}>
                &copy; {new Date().getFullYear()} Blacksheep Meedia. All rights reserved.
            </Box>
        </Box>
    );
};

export default LayoutMain;
