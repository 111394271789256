import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import React, { useEffect, useRef, useState } from "react";
import { ChartSentimentDistributionProps, ChartData } from "./DashboardTypes";
import { ChartEvent, ActiveElement, Chart } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartSentimentDistribution: React.FC<ChartSentimentDistributionProps> = ({ data, onBarClick, setArticlesForSentiment }) => {
    const chartRef = useRef<ChartJS<"bar"> | null>(null);
    const [chartData, setChartData] = useState<ChartData>({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        if (data && data.sentiment_distribution) {
            const sortedKeys = Object.keys(data.sentiment_distribution).sort();
            const updatedChartData = {
                labels: sortedKeys,
                datasets: [
                    {
                        label: "Count",
                        data: sortedKeys.map((key) => data.sentiment_distribution[key].count),
                        backgroundColor: ["#333333", "#666666", "#999999", "#cccccc", "#e0e0e0"],
                    },
                ],
            };
            setChartData(updatedChartData);

            // Update the chart instance manually (optional)
            if (chartRef.current) {
                chartRef.current.update();
            }
        }
    }, [data]);

    const handleClick = (event: ChartEvent, elements: ActiveElement[], chart: Chart<"bar">) => {
        if (!chartRef.current) return;
        if (elements.length > 0) {
            const keyRanges = ['0.0-0.2', '0.2-0.4', '0.4-0.6', '0.6-0.8', '0.8-1.0'];
            const index = elements[0].index;
            const chosenRange = keyRanges[index];

            setArticlesForSentiment?.(data.sentiment_distribution[chosenRange].articles);

            const articlesListBin = document.getElementById("articles-list");
            if (articlesListBin) {
                articlesListBin.innerText = `Articles for selected sentiment range [${chosenRange}]`;
            }
        }
    };

    return (
        <div style={{ width: '400px', margin: 'auto', minHeight: '240px', height: '240px' }}>
            <Bar
                ref={chartRef}
                data={chartData}
                options={{
                    onClick: (event, elements, chart) =>
                        handleClick(event, elements, chart as Chart<"bar">),
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    }
                }}
            />
        </div>
    );
};

export default ChartSentimentDistribution;
