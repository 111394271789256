import React, {useEffect, useState} from "react";
import {
    WikiConcept,
    WikiConcepts,
    DashboardData,
    WikiConceptSentimentDistributionEntry, ArticleAggregationData
} from "./DashboardTypes";
import {Article, NewsCluster} from "../../types";
import {api} from "../../api/apiService";
import { Grid, Paper, Typography } from '@mui/material';
import Top5Chart from './Top5Chart';
import ChartSentimentDistribution from "./ChartSentimentDistribution";
import DashboardArticleList from "./DashboardArticleList";

interface MediaAnalyticsDashboardProps {
    isNational: boolean;
}

const MediaAnalyticsDashboard: React.FC<MediaAnalyticsDashboardProps> = ({ isNational }) => {
    const [data, setDashboardData] = useState<DashboardData>({
        wiki_concepts: { organizations: [], persons: [] },
        countries: []
    });
    const [sentimentChartData, setSentimentChartData] = useState({
        wiki_url: "",
        concept: "",
        count: 0,
        articles: [] as Article[],
        sentiment_average: 0,
        sentiment_last_day: 0,
        sentiment_distribution: {} as Record<string, WikiConceptSentimentDistributionEntry>
    });

    const [articlesForSentiment, setArticlesForSentiment] = useState([] as Article[]);

    function wikiConceptTypeOverview(concepts: WikiConcept[]) {
        concepts.forEach((concept) => {
            const articles = concept.articles;
            concept.sentiment_average = calculateAverageSentiment(articles);

            const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
            const recentArticles = articles.filter((article) => new Date(article.published_at) >= oneDayAgo);
            concept.sentiment_last_day = recentArticles.length
                ? calculateAverageSentiment(recentArticles)
                : 0.5;

            concept.sentiment_distribution = calculateSentimentDistribution(articles);
        });
    }

    function getTopN(arr: WikiConcept[], n: number) {
        return arr.sort((a, b) => b.count - a.count).slice(0, n);
    }

    function mergeWikiConceptSentimentDistributionEntry(first: WikiConceptSentimentDistributionEntry, second: WikiConceptSentimentDistributionEntry) {
        return {
            articles: [...first.articles, ...second.articles].sort((a, b) => {
                return a.article_title.localeCompare(b.article_title)
            }),
            count: first.count + second.count,
        }
    }

    function mergeSentimentDistributions(first: Record<string, WikiConceptSentimentDistributionEntry>, second: Record<string, WikiConceptSentimentDistributionEntry>) {
        let newRecord: Record<string, WikiConceptSentimentDistributionEntry> = {};
        for (const key in first) {
            const value1 = first[key];
            const value2 = second[key];
            if (value1 && value2) {
                newRecord[key] = mergeWikiConceptSentimentDistributionEntry(value1, value2);
            } else if (value1) {
                newRecord[key] = value1;
            } else if (value2) {
                newRecord[key] = value2;
            }
        }
        return newRecord;
    }

    function mergeArticleInfo(first: WikiConcept, second: WikiConcept): WikiConcept {
        if (first === undefined) {
            return second
        }
        return {
            concept: first.concept,
            wiki_url: first.wiki_url,
            articles: [...first.articles, ...second.articles].sort((a, b) => {
                return a.article_title.localeCompare(b.article_title)
            }),
            count: first.count + second.count,
            sentiment_average: (first.sentiment_average + second.sentiment_average) / 2,
            sentiment_last_day: (first.sentiment_last_day + second.sentiment_last_day) / 2,
            sentiment_distribution: mergeSentimentDistributions(first.sentiment_distribution, second.sentiment_distribution)
        }
    }

    // Function to process the incoming data
    const processData = (data: NewsCluster[]): DashboardData => {
        const result: ArticleAggregationData = {
            organizations: {},
            persons: {}
        };
    
        const countryMap: Record<string, WikiConcept> = {};
    
        data.forEach((cluster) => {
            cluster.wiki_concepts.forEach((concept) => {
                const conceptInfo: WikiConcept = {
                    wiki_url: concept.wiki_url,
                    concept: concept.concept,
                    articles: cluster.articles.filter((article) =>
                        article.article_title.includes(concept.concept)
                    ),
                    count: cluster.articles.filter((article) =>
                        article.article_title.includes(concept.concept)
                    ).length,
                    sentiment_average: 0,
                    sentiment_last_day: 0,
                    sentiment_distribution: {}
                };
    
                if (concept.wiki_type === 'persons') {
                    result.persons[conceptInfo.wiki_url] =
                        mergeArticleInfo(result.persons[conceptInfo.wiki_url], conceptInfo);
                } else if (concept.wiki_type === 'organizations') {
                    result.organizations[conceptInfo.wiki_url] =
                        mergeArticleInfo(result.organizations[conceptInfo.wiki_url], conceptInfo);
                }
            });
    
            // Aggregate clusters by country
            const countries = cluster.country.split(',').map((c) => c.trim());
            countries.forEach((countryName) => {
                if (!countryMap[countryName]) {
                    countryMap[countryName] = {
                        wiki_url: "",
                        concept: countryName,
                        articles: [],
                        count: 0,
                        sentiment_average: 0,
                        sentiment_last_day: 0,
                        sentiment_distribution: {}
                    };
                }
                countryMap[countryName].articles.push(...cluster.articles);
                countryMap[countryName].count += cluster.articles.length;
            });
        });
    
        wikiConceptTypeOverview(Object.values(countryMap));
        wikiConceptTypeOverview(Object.values(result.persons));
        wikiConceptTypeOverview(Object.values(result.organizations));
    
        // Return the top 5 from each category
        const n = 5;
        return {
            wiki_concepts: {
                persons: getTopN(Object.values(result.persons), n),
                organizations: getTopN(Object.values(result.organizations), n),
            },
            countries: getTopN(Object.values(countryMap), n)
        };
    };

    // Helper functions as described before
    const calculateAverageSentiment = (articles: Article[]) => {
        const totalSentiment = articles.reduce((sum, article) => sum + article.sentiment_score, 0);
        return totalSentiment / articles.length;
    };

    const calculateSentimentDistribution = (articles: Article[]) => {
        let distribution: Record<string, Article[]> = {};

        // Initialize distribution using loop
        const keyRanges = ['0.0-0.2', '0.2-0.4', '0.4-0.6', '0.6-0.8', '0.8-1.0'];
        keyRanges.forEach(key => distribution[key] = []);

        articles.forEach((article) => {
            const score = article.sentiment_score;
            const range = (Math.floor(score / 0.2) * 0.2).toFixed(1);
            const range_end = (parseFloat(range) + 0.2).toFixed(1);
            const rangeKey = `${range}-${range_end}`;
            distribution[rangeKey].push(article);
        });
        let finalDistributions: Record<string, WikiConceptSentimentDistributionEntry> = {};
        keyRanges.forEach(key => {
            finalDistributions[key]={
                count: distribution[key].length,
                articles: distribution[key]
            }
        });
        return finalDistributions
    };

    function updateArticlesBasedOnSentimentChartDataSelection(wikiConcept: WikiConcept){
        let element = document.getElementById("selected-wiki-concept");
        if(element) {
            element.innerText=wikiConcept.concept;
        }
        let keys = Object.keys(wikiConcept.sentiment_distribution).sort();
        for (const key of keys) {
            if (wikiConcept.sentiment_distribution[key]?.articles?.length > 0) {
                let articlesListBin = document.getElementById("articles-list");
                if(articlesListBin) {
                    articlesListBin.innerText=`Articles for selected sentiment range [${key}]`
                }
                setArticlesForSentiment(wikiConcept.sentiment_distribution[key]?.articles);
                break;
            }
        }
    }

    useEffect(() => {
        api.get("/api/catholic_news_summary")
          .then((response) => {
            const transformed = processData(response.data);
            setDashboardData(transformed);
      
            if (transformed.countries.length > 0) {
              setSentimentChartData(transformed.countries[0]);
              updateArticlesBasedOnSentimentChartDataSelection(transformed.countries[0]);
            }
          })
          .catch((error) => {
            console.error("Error fetching news clusters:", error);
          });
      }, []);

    useEffect(() => {
        if (sentimentChartData !== null) {
            updateArticlesBasedOnSentimentChartDataSelection(sentimentChartData)
        }
    }, [sentimentChartData]);

    return (<div className="p-4">
                <div style={{padding: '20px', marginTop: '-80px'}}>
                    <h2 className="text-xl font-bold mt-4">Top Wiki Concepts</h2>
                    <Grid container spacing={2}>
                    {/* 1) Countries */}
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h6">Countries</Typography>
                        <Top5Chart
                            data={data.countries}
                            setSentimentDistributionData={setSentimentChartData}
                        />
                        </Paper>
                    </Grid>

                    {/* 2) Organizations */}
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h6">Organizations</Typography>
                        <Top5Chart
                            data={data.wiki_concepts.organizations}
                            setSentimentDistributionData={setSentimentChartData}
                        />
                        </Paper>
                    </Grid>

                    {/* 3) Persons */}
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h6">People</Typography>
                        <Top5Chart
                            data={data.wiki_concepts.persons}
                            setSentimentDistributionData={setSentimentChartData}
                        />
                        </Paper>
                    </Grid>
                    </Grid>
                </div>

                <div style={{padding: '20px', marginTop: '-50px'}}>
                    <h2 className="text-xl font-bold mt-4" id="selected-wiki-concept">Selected Wiki Concept</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} style={{padding: '10px'}}>
                                <Typography variant="h6">
                                    Sentiment Analysis
                                </Typography>
                                <ChartSentimentDistribution data={sentimentChartData} setArticlesForSentiment={setArticlesForSentiment}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Paper elevation={3} style={{padding: '10px'}}>
                                <Typography variant="h6" id="articles-list">
                                    {/*{type.charAt(0).toUpperCase() + type.slice(1)}*/}
                                </Typography>
                                <DashboardArticleList articles={articlesForSentiment}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
        </div>
    )
}

export default MediaAnalyticsDashboard;
