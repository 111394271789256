import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useRef } from 'react';
import {ChartData, Top5ChartProps, WikiConcept} from "./DashboardTypes";
import { ChartEvent, ActiveElement, Chart } from "chart.js";

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Top5Chart: React.FC<Top5ChartProps> = ({ data, onBarClick, setSentimentDistributionData }) => {
    const chartRef = useRef<ChartJS<"bar"> | null>(null);

    // Sort data and take the top 5
    const sortedData = [...data].sort((a, b) => b.count - a.count).slice(0, 5);

    // Prepare Chart.js dataset
    const chartData: ChartData = {
        labels: sortedData.map(item => item.concept),
        datasets: [{
            label: 'Count',
            data: sortedData.map(item => item.count),
            backgroundColor: ['#333333', '#666666', '#999999', '#cccccc', '#e0e0e0']
        }]
    };

    function setSelectedItem(wikiConcept: WikiConcept) {
        setSentimentDistributionData?.(wikiConcept)
        onBarClick?.(wikiConcept);
        let element = document.getElementById("selected-wiki-concept")
        if(element) {
            element.textContent=`${wikiConcept.concept}`
        }
    }

    const handleClick = (event: ChartEvent, elements: ActiveElement[], chart: Chart<"bar">) => {
        if (!chartRef.current) return;

        if (elements.length > 0) {
            const index = elements[0].index;
            const clickedItem = sortedData[index];
            setSelectedItem(clickedItem)
        }
    };

    return (
        <div style={{ width: '400px', margin: 'auto' }}>
            <Bar
                ref={chartRef}
                data={chartData}
                options={{
                    onClick: (event, elements, chart) =>
                        handleClick(event, elements, chart as Chart<"bar">),
                    scales: {
                        x: {
                            type: 'category',
                            ticks: {
                                autoSkip: false,
                                maxRotation: 45,
                                minRotation: 45,
                                callback: function (value, index) {
                                    let label = chartData.labels[index] || "";
                                    if (label.length > 10) {
                                        label = label.slice(0, 10) + "...";
                                    }
                                    return label;
                                },
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    },
                }}
            />
        </div>
    );
};

export default Top5Chart;
