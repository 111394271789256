import React, {useContext, useEffect, useState} from 'react';
import { api } from '../../api/apiService';
import axios from 'axios';
import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Accordion,
    AccordionDetails,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatBackendErrors } from "../../utils/errorUtils";
import AnalyticProfileForm from "./AnalyticProfileForm";
import {UserContext} from "../../contexts/UserContext";

interface AnalyticProfile {
    id: number;
    name: string;
    description: string;
    created_by: string;
    created_at: string;
}

export const AnalyticProfilesList = () => {
    const { user } = useContext(UserContext);
    const [profiles, setProfiles] = useState<AnalyticProfile[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [deleteError, setDeleteError] = useState<string | null>(null);
    const [editingProfileId, setEditingProfileId] = useState<number | null>(null); // To track the profile being viewed/edited
    const [profileIdModeEdited, setProfileIdModeEdited] = useState<boolean | null>(null); // To track the profile mode

    const fetchProfiles = async () => {
        try {
            setIsLoading(true);
            const res = await api.get('/api/analytic-profiles');
            setProfiles(res);
        } catch (err) {
            setError('Failed to fetch analytic-profiles');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfiles();
    }, []);

    const handleViewProfile = (id: number) => {
        setEditingProfileId(id); // Set the profile to be viewed
        setProfileIdModeEdited(false)
    };

    const handleEditProfile = (id: number) => {
        setEditingProfileId(id); // Set the profile to be edited
        setProfileIdModeEdited(true)
    };

    const handleDeleteProfile = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this analytic profile?')) {
            try {
                await api.delete(`/api/analytic-profiles/${id}`);
                setProfiles((prev) => prev.filter((profile) => profile.id !== id));
            } catch (error) {
                let parsedError;
                if (axios.isAxiosError(error)) {
                    parsedError = formatBackendErrors(error.response || null);
                } else {
                    parsedError = "We failed to delete the analytic profile";
                }
                setDeleteError(parsedError);
            }
        }
    };

    if (isLoading)
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress size={60} />
            </Box>
        );

    if (error)
        return (
            <Box display="flex" justifyContent="center" mt={2}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );

    return (
        <Box p={3} sx={{marginTop: '-50px'}}>
            {deleteError && (
                <Alert severity="error" onClose={() => setDeleteError(null)} sx={{ mb: 2 }}>
                    <AlertTitle>Error</AlertTitle>
                    {deleteError}
                </Alert>
            )}
            <TableContainer component={Paper} elevation={3} sx={{ borderRadius: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'white', color: 'black' }}>
                            <TableCell sx={{ color: 'black', fontWeight: 'bold' }} align="center">Name</TableCell>
                            <TableCell sx={{ color: 'black', fontWeight: 'bold' }} align="center">Description</TableCell>
                            <TableCell sx={{ color: 'black', fontWeight: 'bold' }} align="center">Created At</TableCell>
                            <TableCell align="center">
                                <Tooltip title="Refresh">
                                    <IconButton color="inherit" onClick={fetchProfiles}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {profiles.map((profile, index) => (
                            <TableRow key={profile.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}>
                                <TableCell align="center">{profile.name}</TableCell>
                                <TableCell align="center">{profile.description}</TableCell>
                                <TableCell align="center">
                                    {new Intl.DateTimeFormat('en-US', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    }).format(new Date(profile.created_at))}
                                </TableCell>
                                <TableCell align="center">
                                    <Box display="flex" justifyContent="center" gap={1}>
                                        {user?.permissions?.analytic_profiles?.read && (
                                            <Tooltip title="View">
                                                <IconButton onClick={() => handleViewProfile(profile.id)}>
                                                    <VisibilityIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {user?.permissions?.analytic_profiles?.update && (
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => handleEditProfile(profile.id)}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {user?.permissions?.analytic_profiles?.delete && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => handleDeleteProfile(profile.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editingProfileId && (
                <Accordion expanded={true}>
                    <AccordionDetails>
                        <AnalyticProfileForm
                            profileId={editingProfileId}
                            mode={profileIdModeEdited?"edit":"view"}
                            onClose={() => setEditingProfileId(null)} // Close the form
                        />
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};
