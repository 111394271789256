import React, {useEffect, useState} from "react";
import { Card, CardContent, Typography, Link, Pagination } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {Article} from "../../types";

interface ArticleListProps {
    articles: Article[];
}

const DashboardArticleList: React.FC<ArticleListProps> = ({articles}) => {
    const [page, setPage] = useState(1);
    const articlesPerPage = 2;

    // Calculate the articles to show based on the current page
    const indexOfLastArticle = page * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

    useEffect(() => {
        setPage(1);
    }, [articles]);

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="container mt-4" style={{maxHeight: "215px", minHeight: "215px", overflow: "auto"}}>
            <div className="row" style={{maxHeight: "150px", minHeight: "150px", overflow: "auto"}}>
                {currentArticles.map((article, index) => (
                    <div key={index} className="col-md-6 mb-3">
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle2">
                                    <Link href={article.url} target="_blank" rel="noopener noreferrer">
                                        {article.article_title}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Source:</strong> {article.source}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Published:</strong> {new Date(article.published_at).toLocaleDateString()}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                <Pagination
                    count={Math.ceil(articles.length / articlesPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </div>
        </div>
    );
};

export default DashboardArticleList;
