import React, { useEffect, useState } from 'react';
import { api } from '../../api/apiService';
import { formatBackendErrors } from '../../utils/errorUtils';
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Alert, CircularProgress, Box } from '@mui/material';

interface UserFormProps {
    userId?: number; // Make profileId optional if passed as a prop
    mode: 'create' | 'edit' | 'view';
    onClose?: () => void;
}

export const UserForm: React.FC<UserFormProps> = ({ mode, userId , onClose}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [canManageUsers, setCanManageUsers] = useState(false);
    const [analyticProfiles, setAnalyticProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isNotEditable, setIsNotEditable] = useState(false);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setUsername('');
        setCanManageUsers(false);
        setSelectedProfileId(null);
        setError(null);
        onClose?.();
    };


    useEffect(() => {
        api.get('/api/analytic-profiles')
            .then((res) => {
                setAnalyticProfiles(res);
                if (mode === 'create' && res.length > 0) {
                    setSelectedProfileId(res[0].id);
                }
            })
            .catch((res) => setError(formatBackendErrors(res)));

        if ((mode === 'edit' || mode === 'view') && userId) {
            setIsFetching(true);
            setIsNotEditable(mode === 'view');
            api.get(`/api/users/${userId}`)
                .then((response) => {
                    const { first_name, last_name, username, can_manage_users, profile_id } = response;
                    setFirstName(first_name);
                    setLastName(last_name);
                    setUsername(username);
                    setCanManageUsers(can_manage_users || false);
                    setSelectedProfileId(profile_id || null);
                })
                .catch((err) => setError(formatBackendErrors(err)))
                .finally(() => setIsFetching(false));
        }
    }, [mode, userId]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const payload = {
            first_name: firstName,
            last_name: lastName,
            username,
            can_manage_users: canManageUsers,
            profile_id: selectedProfileId,
        };

        const apiCall = mode === 'create' ? api.post : api.put;
        const endpoint = mode === 'create' ? '/api/users' : `/api/users/${userId}`;

        apiCall(endpoint, payload)
            .then(() => resetForm())
            .catch((res) => setError(formatBackendErrors(res)))
            .finally(() => setIsLoading(false));
    };

    if (isFetching) {
        return <Box display="flex" justifyContent="center" mt={4}><CircularProgress /></Box>;
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="First Name"
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    disabled={isFetching}
                    InputProps={{
                        readOnly: isNotEditable,
                    }}
                />
                <TextField
                    label="Last Name"
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    disabled={isFetching}
                    InputProps={{
                        readOnly: isNotEditable,
                    }}
                />
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    InputProps={{
                        readOnly: isNotEditable,
                    }}
                />
                {!isNotEditable ? (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Analytic Profile</InputLabel>
                        <Select
                            value={selectedProfileId || ''}
                            onChange={(e) => setSelectedProfileId(e.target.value)}
                            required
                        >
                            {analyticProfiles.map((profile: any) => (
                                <MenuItem key={profile.id} value={profile.id}>
                                    {profile.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <div>
                        <p></p>
                    </div>
                )
                }
                <FormControlLabel
                    control={<Checkbox checked={canManageUsers} onChange={(e) => setCanManageUsers(e.target.checked)} />}
                    label="Can manage users"
                    sx={{ mt: 2 }}
                    disabled={isNotEditable}
                />

                {!isNotEditable && (
                    <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                            {isLoading ? (mode === 'create' ? 'Creating...' : 'Saving...') : mode === 'create' ? 'Create User' : 'Save Changes'}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={() => resetForm()}>
                            Cancel
                        </Button>
                    </Box>
                )}
            </form>
        </Container>
    );
};
