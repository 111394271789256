import React, {useContext, useState} from 'react';
import { Outlet } from 'react-router-dom';
import {Box, Tab, Tabs} from "@mui/material";
import AnalyticProfileForm from "../components/analytic-profiles/AnalyticProfileForm";
import {AnalyticProfilesList} from "../components/analytic-profiles/AnalyticProfilesList";
import CompanyDescriptionForm from "../components/analytic-profiles/CompanyDescriptionForm";
import {UserContext} from "../contexts/UserContext";

export const LayoutAnalyticProfiles = () => {
    const {user} = useContext(UserContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: any): void => {
        setSelectedTab(newValue);
    };

  return (
      <Box style={{backgroundColor: '#f5f5f5', marginTop: '-60px'}}>
        <Box display="flex" alignItems="center" padding={2}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{flexGrow: 2, padding: 2}}>
              {user?.permissions?.analytic_profiles?.update ? (
                  <Tab label="Company description"/>
              ):null}
              {user?.permissions?.analytic_profiles?.read ? (
                  <Tab label="All profiles"/>
              ):null}
              {user?.permissions?.analytic_profiles?.create ? (
                  <Tab label="Create profile"/>
              ):null}


          </Tabs>
        </Box>
        {/* Tab Content */}
        <Box sx={{padding: 2}} flexGrow={1}>
            {selectedTab === 0 && user?.permissions?.analytic_profiles?.update && <div><CompanyDescriptionForm/></div>}
            {selectedTab === 1 && user?.permissions?.analytic_profiles?.read && <div><AnalyticProfilesList/></div>}
            {selectedTab === 2 && user?.permissions?.analytic_profiles?.create && <div><AnalyticProfileForm mode={"create"}/></div>}
        </Box>
        <main>
          <Outlet/>
        </main>
      </Box>
  );
};