import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ForgotPasswordPage} from "./ForgotPasswordPage";
import {ResetPasswordPage} from "./ResetPasswordPage";
import App from "../App";
import {NotFoundPage} from "./NotFound";
import {LayoutUsers} from "../layouts/LayoutUsers";
import {UsersList} from "./users/UsersList";
import {UserForm} from "./users/UserForm";
import {LayoutAnalyticProfiles} from "../layouts/LayoutAnalyticProfiles";
import {AnalyticProfilesList} from "./analytic-profiles/AnalyticProfilesList";
import AnalyticProfileForm from "./analytic-profiles/AnalyticProfileForm";
import React, {useContext} from "react";
import {UserContext} from "../contexts/UserContext";
import {UnauthorizedPage} from "./UnauthorizedPage";
import LayoutMain from "../layouts/LayoutMain";

export function RouterComponent() {
  const {user} = useContext(UserContext);
  return <BrowserRouter>
    <Routes>
      {/* Main Application Layout */}
      {/* Forgot Password - Request Reset Email */}
      <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>

      {/* Reset Password - Link with Token */}
      <Route path="/reset-password" element={<ResetPasswordPage/>}/>

      <Route path="/" element={<App/>}>
        <Route index element={<LayoutMain/>}/>
        <Route path="*" element={<NotFoundPage/>}/>

        {user?.permissions?.users?.read ? (
              <Route index element={<UsersList/>}/>
        ) : (
            <Route index element={<UnauthorizedPage/>}/>
        )}
        {user?.permissions?.users?.create ? (
            <Route path="new" element={<UserForm mode="create"/>}/>
        ) : (
            <Route index element={<UnauthorizedPage/>}/>
        )}
        <Route path=":userId">
          <Route path="edit" element={<UserForm mode="edit"/>}/>
        </Route>


        {/*User Management */}
        <Route path="users" element={<LayoutUsers/>}>
          {user?.permissions?.users?.read ? (
            <Route index element={<UsersList/>}/>
          ) : (
            <Route index element={<UnauthorizedPage/>}/>
          )}
          {user?.permissions?.users?.create ? (
            <Route path="new" element={<UserForm mode="create"/>}/>
          ) : (
            <Route index element={<UnauthorizedPage/>}/>
          )}

          <Route path=":userId">
            <Route path="edit" element={<UserForm mode="edit"/>}/>
          </Route>
        </Route>

        {/*Analytics Profiles*/}
        <Route path="analytic-profiles" element={<LayoutAnalyticProfiles/>}>
          <Route index element={<AnalyticProfilesList/>}/>
          <Route path="new" element={<AnalyticProfileForm mode="create"/>}/>
          <Route path=":profileId">
            <Route path="edit" element={<AnalyticProfileForm mode="edit"/>}/>
          </Route>
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>;
}