import React, { useContext, useEffect, useState } from 'react';
import { api } from '../../api/apiService';
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress, Alert, AlertTitle, Accordion, AccordionDetails,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from '../../contexts/UserContext';
import {UserForm} from "./UserForm";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface User {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    role: string;
    profile_picture: string;
}

export const UsersList = () => {
    const { user } = useContext(UserContext);
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [deleteError, setDeleteError] = useState<string | null>(null);
    const [editingUserId, setEditingUserId] = useState<number | null>(null); // To track the profile being viewed/edited
    const [userIdModeEdited, setUserIdModeEdited] = useState<boolean | null>(null); // To track the profile mode

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const res = await api.get('/api/users');
            setUsers(res);
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleViewUser = (id: number) => {
        setEditingUserId(id); // Set the profile to be viewed
        setUserIdModeEdited(false)
    };

    const handleEditUser = (id: number) => {
        setEditingUserId(id); // Set the profile to be edited
        setUserIdModeEdited(true)
    };

    const handleDeleteUser = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await api.delete(`/api/users/${id}`);
                setUsers((prev) => prev.filter((user) => user.id !== id));
            } catch {
                alert('Failed to delete user');
            }
        }
    };

    if (isLoading)
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    if (error) return <Box color="error.main" textAlign="center">{error}</Box>;

    return (
        <Box p={3} sx={{marginTop: '-50px'}}>
            {deleteError && (
                <Alert severity="error" onClose={() => setDeleteError(null)} sx={{ mb: 2 }}>
                    <AlertTitle>Error</AlertTitle>
                    {deleteError}
                </Alert>
            )}
        <TableContainer component={Paper} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableCell align="center"><strong>First Name</strong></TableCell>
                        <TableCell align="center"><strong>Last Name</strong></TableCell>
                        <TableCell align="center"><strong>Email</strong></TableCell>
                        <TableCell align="center">
                            <IconButton onClick={fetchUsers} aria-label="refresh">
                                <RefreshIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((currentUser) => (
                        <TableRow key={currentUser.id} hover>
                            <TableCell align="center">{currentUser.first_name}</TableCell>
                            <TableCell align="center">{currentUser.last_name}</TableCell>
                            <TableCell align="center">{currentUser.username}</TableCell>
                            <TableCell align="center">
                                <Box display="flex" justifyContent="center" gap={1}>
                                    {user?.permissions?.users?.read && (
                                        <IconButton onClick={() => handleViewUser(currentUser.id)}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    )}
                                    {user?.permissions?.users?.update && (
                                        <IconButton onClick={() => handleEditUser(currentUser.id)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {user?.permissions?.users?.delete && (
                                        <IconButton onClick={() => handleDeleteUser(currentUser.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            {editingUserId && (
                <Accordion expanded={true}>
                    <AccordionDetails>
                        <UserForm
                            userId={editingUserId}
                            mode={userIdModeEdited?"edit":"view"}
                            onClose={() => setEditingUserId(null)} // Close the form
                        />
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};
